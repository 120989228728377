import axios from "@/config/axios";

const initialState = () => {
  return {
    allOtherTests: {},
    testDeleteStatus: false,
    singleTestConfig: {},
    testCreationStatus: false,
    testUpdateStatus: false,
  };
};

const state = initialState();

const getters = {
  getAllOtherTests: (state) => state.allOtherTests,
  getTestDeleteStatus: (state) => state.testDeleteStatus,
  getSingleTestData: (state) => state.singleTestConfig,
  getTestCreationStatus: (state) => state.testCreationStatus,
  getTestUpdateStatus: (state) => state.testUpdateStatus,
};

const mutations = {
  setAllOtherTests(state, data) {
    state.allOtherTests = data;
  },

  setSingleTest(state, data) {
    state.singleTestConfig = data;
  },
  setTestCreationStatus(state, data) {
    state.testCreationStatus = data;
  },
  setTestUpdateStatus(state, data) {
    state.testUpdateStatus = data;
  },
  setTestDeleteStatus(state,data){
    state.testDeleteStatus = data
  },
  reset(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAllOtherTests: async ({ commit }, params) => {
    try {
      commit("setAllOtherTests", null);
      const response = await axios.get("/lab-tests/tests/manually-added-tests", { params });
      commit("setAllOtherTests", response.data);
    } catch (err) {
      commit("setAllOtherTests", null, { root: true });
    }
  },
 

  fetchSingleTest: async ({ commit }, id) => {
    try {
      commit("setSingleTest", null);
      let response = await axios.get(`/lab-tests/${id}`);
      commit("setSingleTest", response.data.data);
    } catch (err) {
      commit("setSingleTest", null);
    }
  },
  createBloodTest: async ({ commit, dispatch }, params) => {
    try {
      commit("setTestCreationStatus", false);
      await axios.post("/lab-tests/add-tests", params);
      commit("setTestCreationStatus", true);
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
  updateBloodTest: async ({ commit, dispatch }, params) => {
    let id = params.id;
    delete params.id;
    try {
      commit("setTestUpdateStatus", false);
      await axios.patch("/lab-tests/" + id, params);
      commit("setTestUpdateStatus", true);
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
  deleteTest: async ({ commit, dispatch }, id) => {
    try {
      commit("setTestDeleteStatus", false);
      await axios.post(`/lab-tests/${id}`);
      commit("setTestDeleteStatus", true);
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
