import AuthMiddleware from "@/middleware/auth"

const AllInsurances = () =>
  import(
    /* webpackChunkName: "insurance" */ "@/components/insurances/AllInsurance"
  )
  const AddInsurances = () =>
    import(
      /* webpackChunkName: "insurance" */ "@/components/insurances/AddInsurance"
    )  
const InsurancesRouter = [
  {
    path: "/all-insurances",
    name: "AllInsurances",
    component: AllInsurances,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "INSURANCES",
    },
  },
  {
    path: "/add-insurances",
    name: "AddInsurances",
    component: AddInsurances,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "INSURANCES",
    },
  },
  {
    path: "/update-insurances/:insurance_id",
    name: "UpdatedInsurances",
    component: AddInsurances,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "INSURANCES",
    },
  }
]

export { InsurancesRouter }
